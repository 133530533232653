<template>
  <MTelegramAuthorization v-if="isTelegramMiniApp" />
  <section v-else class="registration">
    <div class="content">
      <RegistrationForm />
    </div>
  </section>
</template>

<script setup lang="ts">
import { useTelegram } from '@st/telegram/composables/useTelegram'
import RegistrationForm from '../RegistrationForm/RegistrationForm.vue'
import MTelegramAuthorization from '../MTelegramAuthorization/MTelegramAuthorization.vue'

const { isTelegramMiniApp } = useTelegram()
</script>

<style scoped>
.registration {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex-grow: 1;
}
</style>
